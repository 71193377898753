* {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
}

body {
  margin: 0;
  background: black; /*linear-gradient(#472712, #030201);*/
  color: white;

/*
  background-repeat: no-repeat;
  */
  background-attachment: fixed;
  height: 100%;

  background-image: url("assets/paperDamask.jpg");
  background-size: 200px;

}
