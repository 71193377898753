
.App_Grid__3S6n8 {
  display: grid;
  grid-gap: 1px;
  grid-template-columns: auto auto auto auto auto;
  grid-template-rows: auto auto auto;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI',  Oxygen,
    Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-weight: 400;

}

@media only screen and (max-device-width: 480px) {
  .App_Grid__3S6n8 {
    display: block;
    font-size: 16px;
  }
}

@media only screen and (max-device-width: 400px) {
  .App_Grid__3S6n8 {
    display: block;
    font-size: 13px;
    font-weight: 300;
  }
}

.App_icon__awy3_ {
  height: 7em;
  margin-top: 2em;
  opacity: 0.8;
}



.App_Child__3D3ag {
  min-height: 600px;
  max-width: 600px;
  min-width: 90%;
/*
  background: linear-gradient(
      16deg,
      rgb(11, 30, 56) 0%,
      rgb(10, 37, 73) 42%,
      rgb(8, 45, 95) 100%
    ),
    linear-gradient(to right, rgb(37, 62, 99), rgb(11, 31, 70));

  background-repeat: no-repeat;
  background-origin: padding-box, border-box;
  */
  border-radius: 0em;
  grid-column: 3;
  grid-row: 2;
  text-align: center;
  color: white;
  /*
  -webkit-box-shadow: 0px 0px 800px 20px rgba(19, 75, 99, 0.44);
  box-shadow: 0px 0px 800px 20px rgba(19, 75, 99, 0.44);
  */
}

@media only screen and (max-device-width: 400px) {
  .App_tab-list-item__34-Lj {
    min-width: none;
  }
}


.App_curtain__2sOrV {
  background-color: rgba(0, 0, 0, 0.466);
  position: static;
  height: 99em;
  width: auto;
  grid-column-start: 1;
  grid-column-end: 6;
  z-index: 2;
  filter: blur(8px);
  -webkit-backdrop-filter: blur(1.5em);
  backdrop-filter: blur(1.5em);
}


a {
  color: rgb(255, 88, 40);
  text-decoration: none;
}


.App_none__1bA74 {
 position: none;
}

.App_topframe__3LHPb {
  position: absolute;
  top: 1px;
  left: 1px;
  font-size: 0.3em;
}

.App_wallet__1_NBt {
  padding-top: 0.5em;
  border-radius: 0 0 2em 2em;
}



.Navigation_navigation__2_KLR {
  grid-column: 3;
  grid-row: 2;
  position: absolute;
  margin-top: -2em;
  margin-left: 2em;

}

.Navigation_buttonActive__1_TUT {
  padding: 0.4em;
  font-weight: 600;
  color: rgb(253, 85, 36); 
  background-color: #ffffff;
  border: none;
  width: 13em;
  height: 2em;
  border-radius: 3em 3em 0 0;
  margin-right: -1.5em;
  border: 0.3em solid rgba(8, 67, 134, 0.199);
  border-bottom: none;
  z-index: 1;
  position: relative;
  box-shadow: -10px 0px 13px 1px #00000031, 10px -10px 13px 5px #0000001f,
    0px -30px 50px 13px rgba(0, 0, 0, 0);
  cursor: pointer;
}
.Navigation_buttonNonActive__1Ac8v {
  padding: 0.5em;
  font-weight: 600;
  color: rgba(247, 247, 247, 0.726);
  background-color: #5781cfc0;
  border: none;
  width: 11.5em;
  height: 2em;
  border-radius: 3em 3em 0 0;
  margin-right: -1.5em;
  border: 0.2em solid rgba(37, 66, 99, 0.705);
  border-bottom: none;
  z-index: 0;
  position: relative;
  cursor: pointer;
}

.Navigation_buttonNonActive__1Ac8v:hover {
  background-color: #5781cfd7;
  color: rgb(247, 247, 247);
}

.Navigation_buttonActive__1_TUT:focus {
  outline: none;
}
.Navigation_buttonNonActive__1Ac8v:focus {
  outline: none;
}


.tab-list {
  border: 1px solid black;
  
  /*
  border: 1px solid rgb(253, 85, 36);
  border-top: 1px solid #333;
  border-bottom: 1px solid #333;
  */
  background-color: black;
  
  /*
  background: rgba(48, 16, 7);
  background-color: #bc2900;
  */
  padding-left: 0;

  white-space: nowrap;


  width: 100%;
}

@media only screen and (max-device-width: 480px) {
  .tab-list {

    overflow-x: scroll;
    overflow-y: hidden;

    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    -webkit-overflow-scrolling: touch;

    position: fixed;
    bottom: 0;
    background: black;
    /* rgba(48, 16, 7); */
    /*    background-color: black;*/
    height: 55px;
    margin: 0px;
  }
}


/* Hide scrollbar for IE, Edge and Firefox */
.tab-list {
}

.title-item {
  display: inline-block;
  vertical-align: middle;
  list-style: none;
  width: 12em;
  text-align: left;  
}
.title-item img {
  height: 48px;
}

.tab-list-item img {
}

@media only screen and (max-device-width: 480px) {
  .tab-list-item img {
    padding-bottom: 4px;
    height: 24px;
  }
  .title-item img {
    height: 36px;
  }
  
}

.tab-list-item {
  display: inline-block;
  list-style: none;
  margin-bottom: -1px;
  padding: 1rem 0.5rem;

  vertical-align: middle;

}

@media only screen and (max-device-width: 480px) {
  .tab-list-item {
    height: 100%;
    font-weight: 600;
    font-size: 16px;

    vertical-align: middle;
  }
}


.tab-list-active {
  background-color: #ffffff22;
  border-width: 1px 1px 0 1px;
  color: black; /*rgb(253, 85, 36);*/
}



.Profile_sales__275Nk div {
  border: solid white 1px;
}

.Profile_sales__275Nk p {
  font-size: 1em;
  color: white;
}

.Profile_sales__275Nk li {
  font-size: 1em;
}

.Profile_sales__275Nk a {
  color: white;
}

/* ios try 1 */
@supports (-webkit-touch-callout: none) {
  .Profile_sales__275Nk p {
    font-size: 2em;
  }
  .Profile_sales__275Nk li {
    font-size: 2em;
  }
}
/* ios try 2 */
@supports (hanging-punctuation: first) and (font: -apple-system-body) and (-webkit-appearance: none) {
  .Profile_sales__275Nk p {
    font-size: 2em;
  }
  .Profile_sales__275Nk li {
    font-size: 2em;
  }
}

.Profile_info__2SzjA {
  padding-top: 0.5em;

  border-radius: 0 0 2em 2em;
}

.Profile_info__2SzjA p {
  padding-top: 1em;
  color: rgba(250,250,250, 0.7);
}

.Profile_oops__1Rc09 {
  color: rgba(250,250,250, 1);
  font-size: 1em;
}

@media only screen and (max-device-width: 400px) {
  .Profile_info__2SzjA p {
    font-size: 0.4em;
  }
}


.Profile_info__2SzjA button {
  background-color: black; /*rgba(253,85,36);*/
  color: rgb(253, 85, 36); /*white;*/
  font-size: 1em;
  border: rgb(253, 85, 36) solid 2px;
  border-radius: 15px; /* 0.5em; */

  height: 5em;
  width: 20em;
  cursor: pointer;

  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI',  Oxygen,
    Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;

}

.Profile_info__2SzjA button:hover {
  background-color: rgb(48, 16, 7);
}

.Profile_info__2SzjA button:focus {
  outline: none;
}


.Profile_network__33TvS p {
  color: rgb(253, 85, 36);
  font-size: 0.8em;
}

.Profile_info__2SzjA li {
  color: rgb(253, 85, 36);
  font-size: 0.8em;
}

@media only screen and (max-device-width: 400px) {
  .Profile_info__2SzjA li {
    font-size: 0.8em;
  }
}



.Profile_info__2SzjA ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}


.Profile_special__3PDPk h1 {
  margin-top: 1.2em;
}
.Profile_icon__23ugV {
  height: 7em;
  margin-top: 2em;
  opacity: 0.8;
}

.Profile_inputDiv__2-E8p {
  padding-top: 0.1em;
  padding-bottom: 3em;
  height: 3em;
  align-items: center;
}


.Profile_trader__1vP4j {
  height: 3em;
  width: 23em;
  border-radius: 0.5em;
  border: none;
  text-align: center;
}


.Profile_input__288Mz {
  height: 3em;
  width: 15em;
  border-radius: 0.5em;
  border: none;
  text-align: center;
}

input:focus {
  outline: none;
  border: 0.2em solid rgba(139, 60, 23, 0.301);
}

.Profile_totals__10J81 {
  margin-top: 3em;
  height: 3em;
}

.Profile_go__2olLx {
  cursor: pointer;
}

.Profile_goMax__2Ic6x {
  padding-left: 1%;
  margin: 0;
}

.Profile_goMax__2Ic6x h5 {
  font-size: 2em;
}


.Profile_box__1r1fb div {
  width: 280px;
  margin: auto;
}

.Profile_box__1r1fb p {
  color: rgb(253, 85, 36);
  font-size: 0.8em;
}

.Profile_box__1r1fb a {
  color: rgb(253, 85, 36);
  text-decoration: none;
}


.Profile_boxInfo__1Uzp2 {
  /*
  border: rgb(253, 85, 36) solid 0.05em;
  */
  padding: 1em;
  border-radius: 0.5em;
  background-color: #1c2029;

}

.Profile_boxHigh__2hzNG div {
  width: 280px;
  margin: auto;
}

.Profile_boxHigh__2hzNG p {
  color: white;
}
/*  xfont-size: 0.8em;*/


.Profile_digButton__mrdlM {
  background-color: #1c2029; /*rgba(253,85,36);*/
  color: rgb(253, 85, 36); /*white;*/
  font-size: 1em;
  border: rgb(253, 85, 36) solid 2px;
  border-radius: 15px; /* 0.5em; */

  height: 2.5em;
  width: 20em;
  margin-top: 1em;
  cursor: pointer;

  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI',  Oxygen,
    Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;

}

.Profile_digButton__mrdlM:hover {
  background-color: rgb(48, 16, 7);
}


.Profile_digButton__mrdlM:focus {
  outline: none;
 }


.Profile_digButton__mrdlM p {
  display: inline;
  position: relative;
  /*
  bottom: 0.5em;
  */
}



 .Profile_blockButton__AC36r {
  background-color: black; /*rgba(253,85,36);*/
  color: rgb(253, 85, 36); /*white;*/
  font-size: 1em;
  border: rgb(253, 85, 36) solid 2px;
  border-radius: 15px; /* 0.5em; */

  height: 3em;
   width: 12em;
   margin-top: 1em;
   cursor: pointer;
 }

 .Profile_blockButton__AC36r:hover {
   background-color: rgb(48, 16, 7);
 }


 .Profile_blockButton__AC36r:focus {
   outline: none;
  }
  .Profile_blockButton__AC36r p {
    display: inline;
    position: relative;
    bottom: 0.5em;
  }



  .Profile_digIcon__1io-J {
    height: 2em;
    position: relative;
    top: 0.1em;
    right: 1em;
  }


.Profile_checkButton__2xl1Y {
  background-color: black; /*rgba(253,85,36);*/
  color: rgb(253, 85, 36); /*white;*/
  font-size: 1em;
  border: rgb(253, 85, 36) solid 2px;
  border-radius: 15px; /* 0.5em; */

  height: 3em;
  width: 4em;
  margin-top: 1em;
  cursor: pointer;
}

.Profile_checkButton__2xl1Y:hover {
  background-color: rgb(48, 16, 7);
}

.Profile_checkButton__2xl1Y:focus {
  outline: none;
 }

 .Profile_checkButton__2xl1Y:disabled,
 .Profile_checkBbutton__2LQQM[disabled]{
   color: rgba(200,200,200,0.9);
 }

.Profile_checkIcon__3eS4Z {
  height: 2em;
  position: relative;
  top: 0.1em;
  right: 1em;
}

.Profile_checkButton__2xl1Y p {
  display: inline;
  bottom: 0.5em;
}




.Profile_allButton__1LCCX {
  background-color: #1c2029; /*rgba(253,85,36);*/
  color: rgb(253, 85, 36); /*white;*/
  font-size: 1em;
  border: rgb(253, 85, 36) solid 2px;
  border-radius: 15px; /* 0.5em; */

  height: 2.5em;
  width: 2.5em;
  margin-top: 1em;
  cursor: pointer;

  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI',  Oxygen,
  Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;

}

.Profile_allButton__1LCCX:hover {
  background-color: rgb(48, 16, 7);
}


.Profile_allButton__1LCCX:focus {
  outline: none;
 }

.Profile_allButton__1LCCX p {
   display: inline;
   position: relative;
 }



 .Profile_currencyButton__XsIf4 {
  background-color: #1c2029; /*rgba(253,85,36);*/
  color: rgb(253, 85, 36); /*white;*/
  font-size: 1em;
  border: rgb(253, 85, 36) solid 2px;
  border-radius: 15px; /* 0.5em; */

  height: 2.5em;
   width: 9em;
   margin-top: 1em;
   cursor: pointer;

   font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI',  Oxygen,
   Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;

  }

 .Profile_currencyButton__XsIf4:hover {
   background-color: rgb(48, 16, 7);
   background-color: rgb(0, 16, 7);
 }


 .Profile_currencyButton__XsIf4:focus {
   outline: none;
  }

 .Profile_currencyButton__XsIf4 p {
    display: inline;
    position: relative;
  }



 .Profile_importButton__2u6sI {
   background-color: rgb(48, 16, 7);
   background-color: rgb(0, 16, 7);
   color: rgb(253, 85, 36); /*white;*/
   font-size: 1em;
   border: rgb(253, 85, 36) solid 2px;
   border-radius: 15px; /* 0.5em; */
    height: 2em;
   width: 5em;
   margin-top: 0em;
   margin-left: 1em;
   cursor: pointer;

   font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI',  Oxygen,
   Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
 }

 .Profile_importButton__2u6sI:hover {
   background-color: rgba(253,85,36);
 }


 .Profile_importButton__2u6sI:focus {
   outline: none;
  }

 .Profile_importButton__2u6sI p {
    display: inline;
    position: relative;
  }


 .Profile_moonButton__sD-_0 {
   background-color: rgb(125,0,255); /*rgba(253,85,36);*/
   color: white;
   font-size: 1em;
   border: rgb(125, 0, 255) solid 0.05em;
   border-radius: 0.5em;
   height: 3em;
   width: 6em;
   margin-top: 1em;
   cursor: pointer;
 }

 .Profile_moonButton__sD-_0:hover {
   background-color: rgb(48, 0, 7);
 }


 .Profile_moonButton__sD-_0:focus {
   outline: none;
  }

 .Profile_moonButton__sD-_0 p {
    display: inline;
    position: relative;
  }



 .Profile_rampButton__11bV8 {
  background-color: #1c2029; /*rgba(253,85,36);*/
  font-size: 1em;
  border: #21bf73 solid 2px;
  border-radius: 15px; /* 0.5em; */

   color: #21bf73;
   font-size: 1em;
  height: 2.5em;
   width: 9em;
   margin-top: 1em;
   cursor: pointer;

   font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI',  Oxygen,
   Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;

  }

 .Profile_rampButton__11bV8:hover {
   background-color: white;
 }


 .Profile_rampButton__11bV8:focus {
   outline: none;
  }

 .Profile_rampButton__11bV8 p {
    display: inline;
    position: relative;
  }



 .Profile_scanButton__cbF7u {
   background-color: rgba(253,85,36);
   color: white;
   font-size: 1em;
   border: rgb(253, 85, 36) solid 0.05em;
   border-radius: 0.5em;
   height: 3em;
   width: 6em;
   margin-top: 1em;
   margin-bottom: 1em;
   cursor: pointer;
 }

 .Profile_scanButton__cbF7u:hover {
   background-color: rgb(48, 16, 7);
 }


 .Profile_scanButton__cbF7u:focus {
   outline: none;
  }

 .Profile_scanButton__cbF7u p {
    display: inline;
    position: relative;
  }


.Profile_larger__3k5Be {
  font-size: 1.2em;
}

.Profile_stone__1t8sW {
  position: relative;
  top: 2em;
}

.Profile_animatestone__FIf1t
{
  background-image: linear-gradient(
    50deg,
    #574315 0%,
    #7a2110 20%,
    #ff1361 30%,
    #fff800 40%,
    #ffffff 40%,
    #7a2110 80%,
    #574315 100%
  );
  background-size: auto auto;
  background-clip: border-box;
  background-size: 200% auto;
  color: #fff;
  background-clip: text;
  text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: Profile_textclip__2fINk 2s linear infinite;
  display: inline-block;
      font-size: 190px;
}

@keyframes Profile_textclip__2fINk {
  to {
    background-position: -200% center;
  }
}

/*padding-top: 10em;*/

.Profile_header__3Sgq6 {
  height: 7em;

  background-image: url(/static/media/iconHeader.aca6ed0a.png);
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: 50%;

}




.Profile_logo__36Miz {
  padding-top: 0.5em;
  background-image: url(/static/media/icon1500x.164338d3.png);
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover;

  border-radius: 0 0 2em 2em;
}

.Profile_logo__36Miz p {
  padding-top: 1em;
  color: rgba(250,250,250, 0.7);
  font-size: 0.7em;
}

@media only screen and (max-device-width: 400px) {
  .Profile_logo__36Miz p {
    font-size: 0.4em;
  }
}

.Profile_logo__36Miz .Profile_xx__1u_Xg {
  display: flex;
  justify-content: center;
  padding-top: 25%;
  padding-bottom: 25%;
}



.Profile_infologo__2Ao23 {
/*
  background-image: url("../assets/ares1500x30.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  */
  padding-left: 1em;
  padding-right: 1em;
}



.Profile_connectButton__36Cm6 {
  background-color: black; /*rgba(253,85,36);*/
  color: rgb(253, 85, 36); /*white;*/
  font-size: 1em;
  border: rgb(253, 85, 36) solid 2px;
  border-radius: 15px; /* 0.5em; */
  height: 2.5em;
  width: 12em;
  margin-top: 1em;
  cursor: pointer;

  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI',  Oxygen,
  Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;

}

.Profile_connectButton__36Cm6:hover {
  background-color: rgb(48, 16, 7);
  background-color: rgb(0, 16, 7);
}

.Profile_connectButton__36Cm6:focus {
  outline: none;
 }

.Profile_warning__2OIMf {
  font-weight: 700;
  font-size: 1em;
}



.Profile_connect__GRyrO a {
}

.Profile_connect__GRyrO img {
  height: 2.6em;
}

.Profile_connect__GRyrO button {
  background-color: black; /*rgba(253,85,36);*/
  color: rgb(253, 85, 36); /*white;*/
  font-size: 1em;
  border: rgb(253, 85, 36) solid 2px;
  border-radius: 15px; /* 0.5em; */

  height: 3.5em;
  width: 12em;
  height: 5em;
  margin-top: 1em;
  cursor: pointer;

  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI',  Oxygen,
    Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;

}

.Profile_connectlogo__2FpZu {
   width: 3em;
}

.Profile_connecttitle__mkmyY {
  width: 17em;
  vertical-align: middle;
  height: 2.6em;
  padding-left: 2em;
}


.Profile_dbgText__1kmHd {
  font-size: 0.5em;
  color: grey;
}
.Profile_dbgText__1kmHd button {
  background-color: black; /*rgba(253,85,36);*/
  color: rgb(253, 85, 36); /*white;*/
  font-size: 1em;
  border: rgb(253, 85, 36) solid 2px;
  border-radius: 15px; /* 0.5em; */

  border-radius: 0; /* 0.5em; */
  height: 2em;
  width: 12em;
  cursor: pointer;

  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI',  Oxygen,
    Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;

}

.Profile_adviceBox__2LGuH {
  border: 0.05em solid rgb(253, 85, 36) ;
  padding: 1em;
  border-radius: 0.5em; 
}

.Profile_adviceBox__2LGuH p {
  margin: 0;
}

.Profile_pseudoButton__3wi3m {
  padding-left: 2.3em;
}

.Profile_inputUnit__3FMZN {
  display:inline-block;
  width: 4em;
  min-width: 4em;  
}
.Profile_inputWallet__1NhHN {
  display:inline-block;
  width: 28em;
  min-width: 28em;  
  border-radius: 0.5em;
  border: 1px solid black;
  text-align: center;
}

.Profile_Signature__2DfnR {
  padding: 0.1em;
  border: 1px solid black;
  border-radius: 0.5em;
  margin:1em;
}


.Profile_Formular__1zxDa,
.Profile_wrapper__1RgI_ {
  display: flex;
  flex: 1 1 auto;
}



.Profile_Formular__1zxDa  {
  flex-direction: column;
  border: none;
  text-align: center;
  padding-left: 10%;
  padding-right: 10%;
}

.Profile_Formular__1zxDa input {
  padding: 1em;
  margin: 0.5em;
  border-radius: 0.5em;
  border: 1px solid black;
  vertical-align: middle;
}

.Profile_Formular__1zxDa select {
  padding: 0.5em;
  margin: 0.4em;
  width: 5em;
  border-radius: 0.5em;
  vertical-align: middle;
}

.Profile_boxOrder__1levJ {
  background-color: white;
  border-radius: 0.5em;
  border: 1px solid black;

}
.Profile_boxOrder__1levJ input {
  border-color: black;
  background-color: white;
}
.Profile_boxOrder__1levJ button {
  background-color:white;
  color: black;
  margin: 2em;
}
.Profile_boxOrder__1levJ p {
  color: black;
}
.Profile_boxOrder__1levJ span {
  color: black;
}
.Profile_boxOrder__1levJ h1 {
  color: black;
}
.Profile_boxOrder__1levJ h2 {
  color: black;
}
.Profile_boxOrder__1levJ h3 {
  color: black;
}

.Profile_contractOrder__2DpbP p {
  text-align: left;
  margin: 1em;
}
.Profile_contractDisclaimer__3l7Jv p {
  text-align: left;
  margin: 1em;
  font-size: 0.7em;
  color: black
}

.Profile_blink__2gKN2 {
  animation: Profile_blinker__3c5PZ 1.5s linear infinite;
  color: red;
  font-family: sans-serif;
}
@keyframes Profile_blinker__3c5PZ {
  50% {
      opacity: 0;
  }
}
/*
html, body {
  height: 100%;
  margin: 0;
  overflow: hidden;
}

background: #000;
*/

.Origin_logo__3ZMC1 {
  position: absolute;
  color: hsl(136, 100%, 85%);
  text-shadow:
    0 0 .5em #fff,
    0 0 .5em currentColor; 
}

.Origin_base__2U4qs {
  display: flex;
  align-items: center;
  justify-content: center;
}

.Origin_rain__11WIO {
  display: flex;
}
.Origin_rain__11WIO p {
  line-height: 1;
}
.Origin_rain__11WIO span {
  display: block;
  width: 2vmax;
  height: 2vmax;
  font-size: 2vmax;
  color: #9bff9b11;
  text-align: center;
  font-family: "Helvetica Neue", Helvetica, sans-serif;
}

* {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
}

body {
  margin: 0;
  background: black; /*linear-gradient(#472712, #030201);*/
  color: white;

/*
  background-repeat: no-repeat;
  */
  background-attachment: fixed;
  height: 100%;

  background-image: url(/static/media/paperDamask.732d5b29.jpg);
  background-size: 200px;

}

