

.sales div {
  border: solid white 1px;
}

.sales p {
  font-size: 1em;
  color: white;
}

.sales li {
  font-size: 1em;
}

.sales a {
  color: white;
}

/* ios try 1 */
@supports (-webkit-touch-callout: none) {
  .sales p {
    font-size: 2em;
  }
  .sales li {
    font-size: 2em;
  }
}
/* ios try 2 */
@supports (hanging-punctuation: first) and (font: -apple-system-body) and (-webkit-appearance: none) {
  .sales p {
    font-size: 2em;
  }
  .sales li {
    font-size: 2em;
  }
}

.info {
  padding-top: 0.5em;

  border-radius: 0 0 2em 2em;
}

.info p {
  padding-top: 1em;
  color: rgba(250,250,250, 0.7);
}

.oops {
  color: rgba(250,250,250, 1);
  font-size: 1em;
}

@media only screen and (max-device-width: 400px) {
  .info p {
    font-size: 0.4em;
  }
}


.info button {
  background-color: black; /*rgba(253,85,36);*/
  color: rgb(253, 85, 36); /*white;*/
  font-size: 1em;
  border: rgb(253, 85, 36) solid 2px;
  border-radius: 15px; /* 0.5em; */

  height: 5em;
  width: 20em;
  cursor: pointer;

  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI',  Oxygen,
    Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;

}

.info button:hover {
  background-color: rgb(48, 16, 7);
}

.info button:focus {
  outline: none;
}


.network p {
  color: rgb(253, 85, 36);
  font-size: 0.8em;
}

.info li {
  color: rgb(253, 85, 36);
  font-size: 0.8em;
}

@media only screen and (max-device-width: 400px) {
  .info li {
    font-size: 0.8em;
  }
}



.info ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}


.special h1 {
  margin-top: 1.2em;
}
.icon {
  height: 7em;
  margin-top: 2em;
  opacity: 0.8;
}

.inputDiv {
  padding-top: 0.1em;
  padding-bottom: 3em;
  height: 3em;
  align-items: center;
}


.trader {
  height: 3em;
  width: 23em;
  border-radius: 0.5em;
  border: none;
  text-align: center;
}


.input {
  height: 3em;
  width: 15em;
  border-radius: 0.5em;
  border: none;
  text-align: center;
}

input:focus {
  outline: none;
  border: 0.2em solid rgba(139, 60, 23, 0.301);
}

.totals {
  margin-top: 3em;
  height: 3em;
}

.go {
  cursor: pointer;
}

.goMax {
  padding-left: 1%;
  margin: 0;
}

.goMax h5 {
  font-size: 2em;
}


.box div {
  width: 280px;
  margin: auto;
}

.box p {
  color: rgb(253, 85, 36);
  font-size: 0.8em;
}

.box a {
  color: rgb(253, 85, 36);
  text-decoration: none;
}


.boxInfo {
  /*
  border: rgb(253, 85, 36) solid 0.05em;
  */
  padding: 1em;
  border-radius: 0.5em;
  background-color: #1c2029;

}

.boxHigh div {
  width: 280px;
  margin: auto;
}

.boxHigh p {
  color: white;
}
/*  xfont-size: 0.8em;*/


.digButton {
  background-color: #1c2029; /*rgba(253,85,36);*/
  color: rgb(253, 85, 36); /*white;*/
  font-size: 1em;
  border: rgb(253, 85, 36) solid 2px;
  border-radius: 15px; /* 0.5em; */

  height: 2.5em;
  width: 20em;
  margin-top: 1em;
  cursor: pointer;

  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI',  Oxygen,
    Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;

}

.digButton:hover {
  background-color: rgb(48, 16, 7);
}


.digButton:focus {
  outline: none;
 }


.digButton p {
  display: inline;
  position: relative;
  /*
  bottom: 0.5em;
  */
}



 .blockButton {
  background-color: black; /*rgba(253,85,36);*/
  color: rgb(253, 85, 36); /*white;*/
  font-size: 1em;
  border: rgb(253, 85, 36) solid 2px;
  border-radius: 15px; /* 0.5em; */

  height: 3em;
   width: 12em;
   margin-top: 1em;
   cursor: pointer;
 }

 .blockButton:hover {
   background-color: rgb(48, 16, 7);
 }


 .blockButton:focus {
   outline: none;
  }
  .blockButton p {
    display: inline;
    position: relative;
    bottom: 0.5em;
  }



  .digIcon {
    height: 2em;
    position: relative;
    top: 0.1em;
    right: 1em;
  }


.checkButton {
  background-color: black; /*rgba(253,85,36);*/
  color: rgb(253, 85, 36); /*white;*/
  font-size: 1em;
  border: rgb(253, 85, 36) solid 2px;
  border-radius: 15px; /* 0.5em; */

  height: 3em;
  width: 4em;
  margin-top: 1em;
  cursor: pointer;
}

.checkButton:hover {
  background-color: rgb(48, 16, 7);
}

.checkButton:focus {
  outline: none;
 }

 .checkButton:disabled,
 .checkBbutton[disabled]{
   color: rgba(200,200,200,0.9);
 }

.checkIcon {
  height: 2em;
  position: relative;
  top: 0.1em;
  right: 1em;
}

.checkButton p {
  display: inline;
  bottom: 0.5em;
}




.allButton {
  background-color: #1c2029; /*rgba(253,85,36);*/
  color: rgb(253, 85, 36); /*white;*/
  font-size: 1em;
  border: rgb(253, 85, 36) solid 2px;
  border-radius: 15px; /* 0.5em; */

  height: 2.5em;
  width: 2.5em;
  margin-top: 1em;
  cursor: pointer;

  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI',  Oxygen,
  Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;

}

.allButton:hover {
  background-color: rgb(48, 16, 7);
}


.allButton:focus {
  outline: none;
 }

.allButton p {
   display: inline;
   position: relative;
 }



 .currencyButton {
  background-color: #1c2029; /*rgba(253,85,36);*/
  color: rgb(253, 85, 36); /*white;*/
  font-size: 1em;
  border: rgb(253, 85, 36) solid 2px;
  border-radius: 15px; /* 0.5em; */

  height: 2.5em;
   width: 9em;
   margin-top: 1em;
   cursor: pointer;

   font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI',  Oxygen,
   Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;

  }

 .currencyButton:hover {
   background-color: rgb(48, 16, 7);
   background-color: rgb(0, 16, 7);
 }


 .currencyButton:focus {
   outline: none;
  }

 .currencyButton p {
    display: inline;
    position: relative;
  }



 .importButton {
   background-color: rgb(48, 16, 7);
   background-color: rgb(0, 16, 7);
   color: rgb(253, 85, 36); /*white;*/
   font-size: 1em;
   border: rgb(253, 85, 36) solid 2px;
   border-radius: 15px; /* 0.5em; */
    height: 2em;
   width: 5em;
   margin-top: 0em;
   margin-left: 1em;
   cursor: pointer;

   font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI',  Oxygen,
   Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
 }

 .importButton:hover {
   background-color: rgba(253,85,36);
 }


 .importButton:focus {
   outline: none;
  }

 .importButton p {
    display: inline;
    position: relative;
  }


 .moonButton {
   background-color: rgb(125,0,255); /*rgba(253,85,36);*/
   color: white;
   font-size: 1em;
   border: rgb(125, 0, 255) solid 0.05em;
   border-radius: 0.5em;
   height: 3em;
   width: 6em;
   margin-top: 1em;
   cursor: pointer;
 }

 .moonButton:hover {
   background-color: rgb(48, 0, 7);
 }


 .moonButton:focus {
   outline: none;
  }

 .moonButton p {
    display: inline;
    position: relative;
  }



 .rampButton {
  background-color: #1c2029; /*rgba(253,85,36);*/
  font-size: 1em;
  border: #21bf73 solid 2px;
  border-radius: 15px; /* 0.5em; */

   color: #21bf73;
   font-size: 1em;
  height: 2.5em;
   width: 9em;
   margin-top: 1em;
   cursor: pointer;

   font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI',  Oxygen,
   Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;

  }

 .rampButton:hover {
   background-color: white;
 }


 .rampButton:focus {
   outline: none;
  }

 .rampButton p {
    display: inline;
    position: relative;
  }



 .scanButton {
   background-color: rgba(253,85,36);
   color: white;
   font-size: 1em;
   border: rgb(253, 85, 36) solid 0.05em;
   border-radius: 0.5em;
   height: 3em;
   width: 6em;
   margin-top: 1em;
   margin-bottom: 1em;
   cursor: pointer;
 }

 .scanButton:hover {
   background-color: rgb(48, 16, 7);
 }


 .scanButton:focus {
   outline: none;
  }

 .scanButton p {
    display: inline;
    position: relative;
  }


.larger {
  font-size: 1.2em;
}

.stone {
  position: relative;
  top: 2em;
}

.animatestone
{
  background-image: linear-gradient(
    50deg,
    #574315 0%,
    #7a2110 20%,
    #ff1361 30%,
    #fff800 40%,
    #ffffff 40%,
    #7a2110 80%,
    #574315 100%
  );
  background-size: auto auto;
  background-clip: border-box;
  background-size: 200% auto;
  color: #fff;
  background-clip: text;
  text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: textclip 2s linear infinite;
  display: inline-block;
      font-size: 190px;
}

@keyframes textclip {
  to {
    background-position: -200% center;
  }
}

/*padding-top: 10em;*/

.header {
  height: 7em;

  background-image: url("../assets/coin/iconHeader.png");
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: 50%;

}




.logo {
  padding-top: 0.5em;
  background-image: url("../assets/coin/icon1500x.png");
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover;

  border-radius: 0 0 2em 2em;
}

.logo p {
  padding-top: 1em;
  color: rgba(250,250,250, 0.7);
  font-size: 0.7em;
}

@media only screen and (max-device-width: 400px) {
  .logo p {
    font-size: 0.4em;
  }
}

.logo .xx {
  display: flex;
  justify-content: center;
  padding-top: 25%;
  padding-bottom: 25%;
}



.infologo {
/*
  background-image: url("../assets/ares1500x30.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  */
  padding-left: 1em;
  padding-right: 1em;
}



.connectButton {
  background-color: black; /*rgba(253,85,36);*/
  color: rgb(253, 85, 36); /*white;*/
  font-size: 1em;
  border: rgb(253, 85, 36) solid 2px;
  border-radius: 15px; /* 0.5em; */
  height: 2.5em;
  width: 12em;
  margin-top: 1em;
  cursor: pointer;

  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI',  Oxygen,
  Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;

}

.connectButton:hover {
  background-color: rgb(48, 16, 7);
  background-color: rgb(0, 16, 7);
}

.connectButton:focus {
  outline: none;
 }

.warning {
  font-weight: 700;
  font-size: 1em;
}



.connect a {
}

.connect img {
  height: 2.6em;
}

.connect button {
  background-color: black; /*rgba(253,85,36);*/
  color: rgb(253, 85, 36); /*white;*/
  font-size: 1em;
  border: rgb(253, 85, 36) solid 2px;
  border-radius: 15px; /* 0.5em; */

  height: 3.5em;
  width: 12em;
  height: 5em;
  margin-top: 1em;
  cursor: pointer;

  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI',  Oxygen,
    Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;

}

.connectlogo {
   width: 3em;
}

.connecttitle {
  width: 17em;
  vertical-align: middle;
  height: 2.6em;
  padding-left: 2em;
}


.dbgText {
  font-size: 0.5em;
  color: grey;
}
.dbgText button {
  background-color: black; /*rgba(253,85,36);*/
  color: rgb(253, 85, 36); /*white;*/
  font-size: 1em;
  border: rgb(253, 85, 36) solid 2px;
  border-radius: 15px; /* 0.5em; */

  border-radius: 0; /* 0.5em; */
  height: 2em;
  width: 12em;
  cursor: pointer;

  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI',  Oxygen,
    Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;

}

.adviceBox {
  border: 0.05em solid rgb(253, 85, 36) ;
  padding: 1em;
  border-radius: 0.5em; 
}

.adviceBox p {
  margin: 0;
}

.pseudoButton {
  padding-left: 2.3em;
}

.inputUnit {
  display:inline-block;
  width: 4em;
  min-width: 4em;  
}
.inputWallet {
  display:inline-block;
  width: 28em;
  min-width: 28em;  
  border-radius: 0.5em;
  border: 1px solid black;
  text-align: center;
}

.Signature {
  padding: 0.1em;
  border: 1px solid black;
  border-radius: 0.5em;
  margin:1em;
}


.Formular,
.wrapper {
  display: flex;
  flex: 1 1 auto;
}



.Formular  {
  flex-direction: column;
  border: none;
  text-align: center;
  padding-left: 10%;
  padding-right: 10%;
}

.Formular input {
  padding: 1em;
  margin: 0.5em;
  border-radius: 0.5em;
  border: 1px solid black;
  vertical-align: middle;
}

.Formular select {
  padding: 0.5em;
  margin: 0.4em;
  width: 5em;
  border-radius: 0.5em;
  vertical-align: middle;
}

.boxOrder {
  background-color: white;
  border-radius: 0.5em;
  border: 1px solid black;

}
.boxOrder input {
  border-color: black;
  background-color: white;
}
.boxOrder button {
  background-color:white;
  color: black;
  margin: 2em;
}
.boxOrder p {
  color: black;
}
.boxOrder span {
  color: black;
}
.boxOrder h1 {
  color: black;
}
.boxOrder h2 {
  color: black;
}
.boxOrder h3 {
  color: black;
}

.contractOrder p {
  text-align: left;
  margin: 1em;
}
.contractDisclaimer p {
  text-align: left;
  margin: 1em;
  font-size: 0.7em;
  color: black
}

.blink {
  animation: blinker 1.5s linear infinite;
  color: red;
  font-family: sans-serif;
}
@keyframes blinker {
  50% {
      opacity: 0;
  }
}